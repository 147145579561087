html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    height: 100%;
    position: relative;
    min-height: 100%;
    font-family: sans-serif;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#root {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
}

.graph-stats-container {
    flex-direction: column;
}

/*  Table Justify Update */

.MuiTableCell-root div {
    padding:15px;
}



.ordersTable td, .ordersTable th{
    text-align: center !important;
}

.redclass{
    background: #FFE3E3;
}
.pinkclass{
    background: #ffb2ae;
}
.errorclsmsg{
	color:red;
}
.relativeclass{
    position:relative;
}

.filterbox{
    position: absolute;
    top: 0;
    right: 0px;
}


.filterbox label{
    color: black;
    display: inline;
    margin-right: 10px;
}
.descriptioncls * {
    
}
.descriptioncls {
  /*  padding: 12px;
    padding-left: 45px;*/
}

.printcls{
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
    display: inline-block;
    margin-bottom: 0px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 4px;
    float:right;
    margin-top: 7px;
    margin-right: 29px;
    position: fixed;
    right: 10px;
    z-index: 99999;
  }
 .btndisabled{
    color: #000 !important;
    border: 1px solid transparent !important;
    background-color: #efefef !important;
  }
.notecls{
    color: #fff;
    background-color: #204d74;
    border-color: #122b40;
    display: inline-block;
   
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 4px;
    float:left;
    margin-left: 10px;
    z-index: 99999;
  }
.noteclsdiv{
    
    display: none;
  }
  .documentclsdiv{
    
    display: none;
  }
  
.tooltip {
    cursor: pointer;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #0000000a;
  color: #000;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
    left: 50%;
    top: 24px;
    margin-left: -45px;
}
.form-textfield{
    width:58%;
}
.commentboxcls{
      background-color: transparent;
      border: none;
      outline: 0;
      border-bottom: 1px solid black;
      width: 100%;
    }
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.flaggedicon{
	color:rgba(0, 0, 0, 0.54) !important;
}
.menuactive{
	background-color: #1F395E !important;
}
.menuactive .MuiListItemIcon-root,.menuactive .MuiListItemText-primary{
	color:rgba(255, 255,255,1) !important;
}
.suspiciouscls{
	text-align: right;
	padding-top:10px;
	padding-right:120px;
}
.suspiciouscls small{
	color: #1F395E !important;
	font-size: 12px;
}
.dashboard-filterbox{
	width:50%;
}
.radio__label{
	
	font-size: 15px;
}
.radio__input input{
	width: 36px;
    height: 21px;
    /* margin-top: 5px; */
    position: absolute;
    margin-right: 0.3rem;
    border-radius: 50%;
    border-style: solid;
    display: inline-block;
    position: relative;
    vertical-align: bottom;
}
@media print {
    #root{
        display: none;
    }
    #drawer-notes, #drawer-notes .MuiDrawer-paper{
        position: relative !important;

    }


}